import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Select,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { saveProductPlanogramMapping } from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { getFrameCollection } from "../OfficeCollection/sagas";
import { LifeCycleStatusList, SKUGrade } from "src/util/utils";
import { DATE_FORMAT } from "src/util/columns";
import moment from "moment";

interface AddPPMappingType {
  visible: boolean;
  setVisibleStatus: any;
  onLoad: any;
  data: any;
  setData: any;
}

function AddPPMapping({
  visible,
  setVisibleStatus,
  onLoad,
  data,
  setData,
}: AddPPMappingType) {
  const { Option } = Select;
  const [frameDetailForm] = Form.useForm();
  const [FrameArrangerID, setFrameArrangerId] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  let memoizeDate = moment().format(DATE_FORMAT);
  const [statusDate, setStatusDate] = useState<any>(memoizeDate);

  useEffect(() => {
    if (Object.keys(data).length) {
      const editFieldObj = {
        ID1: data?.ID1,
        FrameArrangerID: data?.FrameArrangerID,
        Collection: data?.Collection,
        Vendor: data?.Vendor,
        Model: data?.Model,
        StyleName: data?.StyleName,
        ColorNumber: data?.ColorNumber,
        ColorDescription: data?.ColorDescription,
        EyeSize: data?.EyeSize,
        Bridge: data?.Bridge,
        Temple: data?.Temple,
        A: data?.A,
        B: data?.B,
        ED: data?.ED,
        Material: data?.Material,
        Edge: data?.Edge,
        UPC: data?.UPC,
        SKUQty: data?.SKUQty,
        FrameType: data?.FrameType,
        Gender: data?.Gender,
        FrameName: data?.FrameName,
        MEDCost: data?.MEDCost,
        Wholesale: data?.Wholesale,
        Retail: data?.Retail,
        RecallDate: data?.RecallDate,
        ItemGroup: data?.ItemGroup,
        NoReplenishmentDate: data?.NoReplenishmentDate?.substring(0, 10),
        EffectiveDate: data?.EffectiveDate?.substring(0, 10),
        PhasingInStyle: data?.PhasingInStyle,
        Min: data?.Min,
        Max: data?.Max,
        StockedAtDC: data?.StockedAtDC,
        LiveCost: data?.LiveCost,
        LiveWholeSale: data?.LiveWholeSale,
        FrameColorFamily: data?.FrameColorFamily,
        FrameShape: data?.FrameShape,
        AgeGroup: data?.AgeGroup,
        Rank: data?.Rank,
        ReceiptDate: data?.ReceiptDate?.substring(0, 10),
        ItemType: data?.ItemType,
        LiveRetail: data?.LiveRetail,
        LifeCycleStatus: data?.LifeCycleStatus,
        LifeCycleStatusDate:
          data.LifeCycleStatusDate !== null
            ? moment(data.LifeCycleStatusDate)
            : "",
        SKUGrade: data?.SKUGrade,
      };
      frameDetailForm.setFieldsValue(editFieldObj);
      setStatusDate(
        data.LifeCycleStatusDate !== null
          ? moment(data.LifeCycleStatusDate)
          : ""
      );
    }
  }, [data]);

  useEffect(() => {
    Promise.all([getFrameCollection({})]).then((res) => {
      const sortedFAId: any = res[0]?.sort((a: any, b: any) => {
        return a.Vendor !== null && a.Vendor.localeCompare(b.Vendor, "sv");
      });
      setFrameArrangerId(sortedFAId);
    });
  }, []);

  const handleClose = () => {
    // setConfirmLoading(true);
    setData([]);
    setVisibleStatus(false);
    frameDetailForm.resetFields();
    // setConfirmLoading(false);
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      const fields = frameDetailForm.getFieldsValue();
      let res;
      const frameArranger = FrameArrangerID.find(
        (elem: any) => elem.FrameArrangerID === fields.FrameArrangerID
      ) as any;
      let payload: any = {
        ID1: fields.ID1,
        FrameArrangerID: fields.FrameArrangerID,
        Collection: frameArranger.Collection,
        Vendor: frameArranger.Vendor,
        Model: fields.Model,
        StyleName: fields.StyleName,
        ColorNumber: fields.ColorNumber,
        ColorDescription: fields.ColorDescription,
        EyeSize: fields.EyeSize,
        Bridge: fields.Bridge,
        Temple: fields.Temple,
        A: fields.A,
        B: fields.B,
        ED: fields.ED,
        Material: fields.Material,
        Edge: fields.Edge,
        UPC: fields.UPC,
        SKUQty: Number(fields.SKUQty),
        FrameType: fields.FrameType,
        Gender: fields.Gender,
        FrameName: fields.FrameName,
        MEDCost: fields.MEDCost,
        Wholesale: fields.Wholesale,
        Retail: fields.Retail,
        RecallDate: fields.RecallDate,
        ItemGroup: fields.ItemGroup,
        NoReplenishmentDate: fields.NoReplenishmentDate,
        EffectiveDate: fields.EffectiveDate,
        PhasingInStyle: Number(fields.PhasingInStyle ? 1 : 0),
        Min: Number(fields.Min),
        Max: Number(fields.Max),
        StockedAtDC: Number(fields.StockedAtDC ? 1 : 0),
        LiveCost: Number(fields.LiveCost),
        LiveWholeSale: Number(fields.LiveWholeSale),
        FrameColorFamily: fields.FrameColorFamily,
        FrameShape: fields.FrameShape,
        AgeGroup: fields.AgeGroup,
        Rank: Number(fields.Rank),
        ReceiptDate: fields.ReceiptDate,
        ItemType: Number(fields.ItemType),
        LiveRetail: fields.LiveRetail,
        LifeCycleStatus: fields.LifeCycleStatus,
        LifeCycleStatusDate: fields.LifeCycleStatusDate,
        SKUGrade: fields.SKUGrade,
      };
      payload = {
        ...payload,
        ID1: data?.ID1,
      };

      res = await saveProductPlanogramMapping(payload);

      if (res) {
        showSuccessNotification(res);
        handleClose();
        onLoad();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      if (error?.errorFields?.length > 0)
        showErrorNotification(
          "Please enter all mandatory fields and check the error massages"
        );
      else showErrorNotification(error.response);
    }
  };

  const drawerTitle = Object.keys(data).length
    ? "Edit Product Planogram"
    : "Add Product Planogram";

  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk}>Save</Button>
          </Space>
        }
      >
        {/* <Spin tip="Loading..." spinning={confirmLoading}> */}
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <Card>
              <div>
                <Form.Item
                  label="Frame Arranger ID"
                  name="FrameArrangerID"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select Frame Arranger ID",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Frame Arranger ID"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[0] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                  >
                    {FrameArrangerID?.map((item: any, index) => {
                      return (
                        <Option key={index} value={item?.FrameArrangerID}>
                          {item?.Vendor}-{item?.Collection}-
                          {item?.FrameTypeDescription} ({item?.FrameArrangerID})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
            <Card>
              <div>
                <Form.Item
                  label="Model"
                  name="Model"
                  className="label-text region-field"
                >
                  <Input name="Model" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Style Name"
                  name="StyleName"
                  className="label-text region-field"
                >
                  <Input name="StyleName" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Color Number"
                  name="ColorNumber"
                  className="label-text region-field"
                >
                  <Input name="ColorNumber" maxLength={10} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Color Description"
                  name="ColorDescription"
                  className="label-text region-field"
                >
                  <Input name="ColorDescription" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Eye Size"
                  name="EyeSize"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^-?\d+(\.\d{1,2})?$/),
                      message: `Eye size should be decimal`,
                    },
                  ]}
                >
                  <Input name="EyeSize" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Bridge"
                  name="Bridge"
                  className="label-text region-field"
                >
                  <Input name="Bridge" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Temple"
                  name="Temple"
                  className="label-text region-field"
                >
                  <Input name="Temple" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="A"
                  name="A"
                  className="label-text region-field"
                >
                  <Input name="A" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="B"
                  name="B"
                  className="label-text region-field"
                >
                  <Input name="B" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="ED"
                  name="ED"
                  className="label-text region-field"
                >
                  <Input name="ED" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Material"
                  name="Material"
                  className="label-text region-field"
                >
                  <Input name="Material" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Edge"
                  name="Edge"
                  className="label-text region-field"
                >
                  <Input name="Edge" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="UPC"
                  name="UPC"
                  className="label-text region-field"
                  rules={[{ required: true, message: "Please enter UPC" }]}
                >
                  <Input name="UPC" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="SKU Qty"
                  name="SKUQty"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `SKU Qty should be numeric`,
                    },
                  ]}
                >
                  <Input name="SKUQty" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Type"
                  name="FrameType"
                  className="label-text region-field"
                >
                  <Input name="FrameType" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Gender"
                  name="Gender"
                  className="label-text region-field"
                >
                  <Input name="Gender" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Name"
                  name="FrameName"
                  className="label-text region-field"
                >
                  <Input name="FrameName" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="MED Cost"
                  name="MEDCost"
                  className="label-text region-field"
                >
                  <Input name="MEDCost" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Wholesale"
                  name="Wholesale"
                  className="label-text region-field"
                >
                  <Input name="Wholesale" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Retail"
                  name="Retail"
                  className="label-text region-field"
                >
                  <Input name="Retail" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Recall Date"
                  name="RecallDate"
                  className="label-text region-field"
                >
                  <Input name="RecallDate" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Item Group"
                  name="ItemGroup"
                  className="label-text region-field"
                >
                  <Input name="ItemGroup" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="NoReplenishDate"
                  name="NoReplenishmentDate"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^\d{4}-\d{2}-\d{2}$/),
                      message: `NoReplenishDate should be date format YYYY-MM-DD`,
                    },
                  ]}
                >
                  <Input name="NoReplenishmentDate" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Effective Date"
                  name="EffectiveDate"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^\d{4}-\d{2}-\d{2}$/),
                      message: `Effective Date should be date format YYYY-MM-DD`,
                    },
                  ]}
                >
                  <Input name="EffectiveDate" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  valuePropName="checked"
                  name="PhasingInStyle"
                  className="label-text region-field"
                >
                  <Checkbox name="PhasingInStyle" value={true}>
                    {" "}
                    Phasing{" "}
                  </Checkbox>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Min"
                  name="Min"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Min should be numeric`,
                    },
                  ]}
                >
                  <Input name="Min" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Max"
                  name="Max"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Max should be numeric`,
                    },
                  ]}
                >
                  <Input name="Max" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  valuePropName="checked"
                  name="StockedAtDC"
                  className="label-text region-field"
                >
                  <Checkbox name="StockedAtDC" value={true}>
                    {" "}
                    Stocked at DC{" "}
                  </Checkbox>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="LiveCost"
                  name="LiveCost"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Live cost should be numeric`,
                    },
                  ]}
                >
                  <Input name="LiveCost" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="LiveWholeSale"
                  name="LiveWholeSale"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Live whole sale should be numeric`,
                    },
                  ]}
                >
                  <Input name="LiveWholeSale" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="LiveRetail"
                  name="LiveRetail"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Live retail should be numeric`,
                    },
                  ]}
                >
                  <Input name="LiveRetail" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Color Family"
                  name="FrameColorFamily"
                  className="label-text region-field"
                >
                  <Input name="FrameColorFamily" maxLength={6} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Shape"
                  name="FrameShape"
                  className="label-text region-field"
                >
                  <Input name="FrameShape" maxLength={9} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Age Group"
                  name="AgeGroup"
                  className="label-text region-field"
                >
                  <Input name="AgeGroup" maxLength={8} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Rank"
                  name="Rank"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Rank should be numeric`,
                    },
                  ]}
                >
                  <Input name="Rank" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Receipt Date"
                  name="ReceiptDate"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^\d{4}-\d{2}-\d{2}$/),
                      message: `Receipt Date be date format YYYY-MM-DD`,
                    },
                  ]}
                >
                  <Input name="ReceiptDate" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Item Type"
                  name="ItemType"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Item type should be numeric`,
                    },
                  ]}
                >
                  <Input name="ItemType" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Life Cycle Status"
                  name="LifeCycleStatus"
                  className="label-text region-field"
                  rules={[{ message: "Please select Life Cycle Status" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Life Cycle Status"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {LifeCycleStatusList?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item} label={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Life Cycle Status Date"
                  name="LifeCycleStatusDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="LifeCycleStatusDate"
                    value={statusDate && moment(statusDate)}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                    placeholder="Select Date"
                    onChange={(value: DatePickerProps["value"]) =>
                      setStatusDate(moment(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="SKU Grade"
                  name="SKUGrade"
                  className="label-text region-field"
                  rules={[{ message: "Please select SKU Grade" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select SKU Grade"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {SKUGrade?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item} label={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
        {/* </Spin> */}
      </Drawer>
    </>
  );
}

export default AddPPMapping;
